// I would clean this but I'm scared
.home {
  padding-bottom: 70px;
  .aboutMe {
    width: 100%;
    margin: 0 auto;
    margin-top: 3%;

    h1 {
      margin: 0 15%;
      font-size: 3vw;
      font-weight: bold;
    }

    h2 {
      
      width: 50%;
      margin: 10px auto;
      font-size: 2rem;
      @include media("<=desktop") {
        margin: 20px auto;
        width: 80%;
      }
      @include media("<=phone") {
        width: 90%;
        font-size: 1.5rem;
      }
    }

    p {
      width: 60%;
      margin: 20px auto;
      font-size: 1vw;
      text-align: center;

      @include media("<=phone") {
        font-size: 3vw;
      }
    }
  }

  //BANNER
  .banner-container {
    width: 100%;
    height: 100vh;

    .banner {
      width: 100%;
      position: absolute;
      z-index: 1;
      top: 35%;

      @include media("<=tablet") {
        margin: auto auto;
      }

      @include media("<=phone") {
        margin: auto;
      }

      h1 {
        line-height: 100%;
        margin: 0 15%;

        @include media("<=tablet") {
          text-align: center;
          font-size: 2.5rem;
          margin: 25px 10px;
        }

        font-size: 3vw;
        font-weight: 700;

        @include media("<=phone") {
          text-align: center;
          font-size: 2.5rem;
        }

        span {
          font-feature-settings: "pnum";
          display: inline-flex;
          font-size: 3vw;
          font-weight: 700;

          margin-top: auto;
          @include media("<=tablet") {
            text-align: center;
            font-size: 2.5rem;
            margin: 10px;
          }
          @include media("<=phone") {
            font-size: 2.5rem;
          }
        }
      }

      h2 {
        margin: 0 15%;
        font-size: 3vw;
        font-weight: 700;

        @include media("<=tablet") {
          font-size: 2.7rem;
          text-align: center;
        }

        @include media("<=phone") {
          font-size: 2rem;
          text-align: center;
        }
      }

      .mobile-break {
        display: none;

        @include media("<=tablet") {
          display: block;
        }
      }
    }

    //BANNER-END

    //ARROW
    .arrow-down {
      a:visited,
      a:hover,
      a:focus {
        text-decoration: none;
        color: #212121;
      }

      width: 100%;
      position: absolute;

      bottom: 20%;

      svg {
        margin-left: auto;
        margin-right: auto;
        fill: "#212121";
        display: block;
        width: 50px;
      }
    }

    img,
    picture {
      .background-pic {
        display: block;
        -webkit-mask-image: -webkit-gradient(
          linear,
          right top,
          right bottom,
          from(rgba(0, 0, 0, 1) 90%),
          to(rgba(0, 0, 0, 0))
        );
        mask-image: linear-gradient(
          to bottom,
          rgba(0, 0, 0, 1) 90%,
          rgba(0, 0, 0, 0)
        );
      }

      display: block;
      position: absolute;
      z-index: 0;
      bottom: 0;
      width: 100%;
    }
  }
}
