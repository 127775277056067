//Inner Expanded Card CSS
$textcolor: white;
.expanded-content {
  .content-header {
    text-align: center;
    color: $textcolor;
    margin: 10 10px;
  }
}
.content-text {
  text-indent: 30px;
  margin: 20px 10%;
  font-size: 1rem;
  color: $textcolor;
  a {
    color: #2196f3;
    font-size: inherit;
    font-weight: inherit;
    text-decoration: none;
  }
}
.bold-opening {
  color: #4fc3f7;
  font-weight: bold;
}
.content-img {
  display: block;
  max-height: 400px;
  max-width: 100%;
  margin: 10px 0;
}
.auto-h {
  margin-left: auto;
  margin-right: auto;
}
.img-description {
  font-size: 1rem;
  font-weight: 400;
  color: $textcolor;
  text-align: center;
  font-style: italic;
  @include media("<=phone") {
    font-size: 0.75rem;
  }
}
.content-subheader {
  @include media("<=phone") {
    line-height: unset;
    font-size: 1rem;
  }
  text-align: center;
  color: $textcolor;
  text-decoration: underline;
  margin: 10 10px;
}
.content-tab {
  color: $textcolor;
  margin: 50px 10%;
  font-size: 2rem;
}
.content-video {
  position: relative;
  overflow: hidden;
  width: 90%;
  margin: 0 auto;
  padding-top: 56.25%;
  iframe {
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    width: 100%;
    height: 100%;
  }
}
.content-list {
  margin: 20px 10%;
  font-size: 1rem;
  color: $textcolor;
}
.content-github {
  margin: 100px 0;
  display: flex;
  justify-content: center;
  width: 100%;
  a {
    .logo {
      fill: #eeeeee;
      width: 50px;
      height: 50px;

      transition: fill 0.3s;
      &:hover {
        fill: rgb(60, 123, 196);
      }
    }
  }
}
