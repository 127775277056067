.header {
  position: fixed;
  height: 70px;
  top: 20px;
  z-index: 4;
  margin-left: 5%;
  margin-right: auto;
  @include media("<=phone") {
    width: 100%;
    height: unset;
    margin: 0;
    top: 0;
  }

  .logo {
    text-align: center;
    padding: 0;
    margin: 0 0 0 20px;
    @include media("<=phone") {
      margin: 0 auto;
    }
    .svg {
      -webkit-filter: drop-shadow(3px 3px 2px rgba(0, 0, 0, 0.7));
      filter: drop-shadow(0px 3px 2px rgba(0, 0, 0, 0.7));

      display: block;
      transform: scale(1.2);
      width: 50px;
      @include media("<=phone") {
        padding-top: 20px;
        margin: 0 auto;
      }
    }
    .desktop {
      vertical-align: middle;
      font-size: 1.4rem;
      font-weight: 700;
      text-decoration: none;
      letter-spacing: 0.3rem;
      color: $black;
      @include media("<=phone") {
        font-size: 1.5rem;
        letter-spacing: 0.7rem;
        width: 100%;
      }
    }

    .small {
      display: block;
      font-size: 0.7rem;
      @include media("<=phone") {
        font-size: 0.8rem;
      }
    }
  }
  .addOnMenu {
    margin: auto 20px;
    position: relative;
    text-align: center;
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    @include media("<=phone") {
      justify-content: space-around;
      padding-bottom: 20px;
    }
    h3 {
      margin: 0 10px;
      display: inline;
      justify-content: space-around;
      font-weight: 700;
      text-decoration: none;

      .menuItem {
        font-weight: 700;
        font-size: 1.1rem;
      }
    }

    h3 {
      text-decoration: none;
      justify-content: space-around;
      font-weight: 700;
      height: 100%;
      padding: 10px 5px;
      margin: 0 4px;
    }
    .menuItem {
      font-weight: 700;
      font-size: 1.1rem;
    }
  }
}
