@import url("https://fonts.googleapis.com/css2?family=Inter:wght@400;700&display=swap");
@import "./variables.scss";
@import "./breakpoints.scss";
@import "./header.scss";
@import "./banner.scss";
@import "./card.scss";
@import "./photos.scss";
@import "./content.scss";
body,
html,
#root {
  height: 100%;
  background: $white;
  padding: 0;

  margin: 0;
  font-family: "Inter", -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto",
    "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  ::selection {
    color: rgb(166, 210, 247);
    background: rgb(109, 109, 109);
  }
}
* {
  -webkit-overflow-scrolling: touch;
}
.container {
  z-index: 4;
  background-color: transparent;
  display: block;
  height: 100%;
  border-radius: 5px;
  @include media("<=phone") {
    margin: 0 auto;
    border-radius: 0px;
    width: 100%;
  }

  .row {
    display: flex;
    height: 100%;
    padding: 0 auto;

    @include media("<=phone") {
      display: block;
    }
  }

  .h-center {
    justify-content: center;
  }

  .v-center {
    align-items: center;
  }

  .space-between {
    justify-content: space-between;
  }

  .txt-align {
    text-align: center;
  }
}

.container-fluid {
  width: 100%;
  height: 100%;

  .row {
    display: flex;
    height: 100%;
  }
}

.App {
  z-index: 2;
  transform: translateY(0);
  position: relative;
}
footer {
  background-color: #212121;
  box-shadow: 0 -2px 5px black;
  .seeme {
    margin: 0;
    padding: 10px 0;
    text-align: center;

    color: #eeeeee;
  }
}
.footer-links {
  padding: 25px;
  display: flex;
  justify-content: center;
  .logo-link {
    svg {
      fill: #eeeeee;

      transition: fill 0.3s;
    }
    margin: 0 5%;
    .instagram {
      #insta_hover {
        opacity: 0;

        transition: opacity 0.3s;
      }
    }
    &:hover {
      .linkedin {
        fill: rgb(0, 119, 181);
      }
      #insta_hover {
        opacity: 1;
      }
      .github {
        fill: #2b414e;
      }

      transition: fill 0.3s;

      transition: opacity 0.3s;
    }
  }
}
.sourcecode {
  margin: 0;
  a {
    text-decoration: none;
    color: white;
  }
  padding: 5px 10px;
  font-weight: 250;
  text-align: right;
  font-size: 0.75rem;
}
.linkhover {
  transition: color 0.3s;
  color: #2196f3;
  text-decoration: none;
  &:hover {
    color: rgb(179, 28, 179);
    transition: color 0.3s;
  }
}
.mail {
  text-align: center;
  font-size: 8vw;
}
.amazon {
  color: #ec912d;
}
