//Card Specific Related CSS

//scrollBar stuff
@mixin scrollbars(
  $size,
  $foreground-color,
  $background-color: mix($foreground-color, white, 50%)
) {
  // For Google Chrome
  &::-webkit-scrollbar {
    width: $size;
    height: $size;
  }

  &::-webkit-scrollbar-thumb {
    background: $foreground-color;
  }

  &::-webkit-scrollbar-track {
    background: $background-color;
  }

  // For Internet Explorer
  & {
    scrollbar-face-color: $foreground-color;
    scrollbar-track-color: $background-color;
  }
}

.project-title-div {
  margin: 0 auto;
  margin-top: 3%;
  @include media("<=phone") {
    margin-top: 10%;
  }

  h1 {
    margin: 0 15%;
    font-size: 2rem;

    font-weight: bold;

    @include media("<=phone") {
      margin-top: 5%;
      font-size: 10vw;
      text-align: center;
    }
  }
}

.project-container {
  width: 100%;
  @include media("<=phone") {
    margin: auto;
  }
  //this is called dates because it only used to hold the date of the project. now it is a lot more important...
  .dates {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    width: 100%;
    margin: 0 auto;
    margin-top: 20px;
  }
}

.card-container {
  margin-left: 1rem;
  margin-right: 1rem;
}

.card {
  overflow: hidden;
  box-shadow: 0 0 10px -2px rgba(0, 0, 0, 0.2);
  box-sizing: border-box;
}

@keyframes slideInFromLeft {
  0% {
    transform: translateY(50%);
    opacity: 0;
  }
  100% {
    opacity: 1;
    transform: translateX(0);
  }
}

.expanded {
  z-index: 3;
  overflow-y: scroll;
  animation: 0.2s ease-in-out 0s 1 slideInFromLeft;
  box-shadow: 0 10px 20px rgba(0,0,0,0.19), 0 6px 6px rgba(0,0,0,0.23);
  @include scrollbars(0, black, black);
  background-color: #1c1c1e;
  position: fixed;
  top: 55px;
  left: 0;
  right: 0;
  margin-left: auto;
  margin-right: auto;
  width: 800px;
  height: 90vh;

  @include media("<=phone") {
    height: 95vh;

    z-index: 5;
    width: 100%;
    margin: 0 auto;
  }

  .content {
    display: block;
    color: #9d9ca1;
    margin: 5% 5%;
    font-size: 1.5em;
  }
}
/**Yes. I know I have two expanded classes here. But the one 
above really is only for the expanded card itself while this one has all the inner components.
*/
.expanded {
  border-radius: 10px;

  .title-bg {
    position: sticky;
    cursor: pointer;
    z-index: 1;
    margin: auto;
    height: 20%;
    top: 0;
    display: flex;
    flex-direction: row-reverse;
    align-items: center;
    justify-content: space-between;

    picture {
      position: relative;
      width: 40%;
      height: 100%;
      img {
        width: 100%;
        height: 100%;
        object-fit: cover;
      }
      -webkit-mask-image: -webkit-gradient(
        linear,
        right top,
        left top,
        from(rgba(0, 0, 0, 1) 90%),
        to(rgba(0, 0, 0, 0))
      );
      mask-image: linear-gradient(
        to left,
        rgba(0, 0, 0, 1) 90%,
        rgba(0, 0, 0, 0)
      );

      @include media("<=phone") {
        -webkit-mask-image: none;
        mask-image: none;
        width: 100%;
        padding-bottom: 10%;
      }
    }

    .title-div {
      display: flex;
      position: relative;
      height: 100%;
      width: 75%;

      @include media("<=phone") {
        width: 100%;
        position: absolute;
        left: 0;

        h1 {
          margin: auto;
        }
      }
    }

    h1 {
      text-shadow: 0px 2px 3px #212121;
      margin: auto;
      font-size: 3vw;
      color: white;

      @include media("<=phone") {
        font-size: 6vw;
      }
    }
  }
}

.expanded-content{
  @include media("<=phone") {
    margin-bottom: 100px;
  }
}
.close-button {
  position:absolute;
  z-index: 6;
  top: 0px;
  right: 0%;
  width: 50px;
  height: 50px;
  background-color: rgba(255, 255, 255, 0.281);
  backdrop-filter: blur(6px);
  border-radius: 100px;
  cursor: pointer;
  &:hover {
    background-color: rgba(55, 55, 55, 0.281);
    fill: white;
  }
}

.compact {
  cursor: pointer;
  position: relative;
  border-radius: 5px;
  background-color: #1c1c1e;
  width: 27vw;
  height: 27vw;
  max-width: 300px;
  max-height: 300px;
  margin: 5px 0;

  @include media("<=phone") {
    width: 70vw;
    height: 70vw;
  }

  .title-bg {
    height: 100%;
  }

  img {
    object-fit: cover;
    width: 100%;
    height: 100%;
  }

  .title {
    position: absolute;
    text-shadow: 0px 2px 3px #212121;
    color: black;
    margin: auto;
    width: 100%;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    text-align: center;
    font-size: 2rem;

    @include media("<=phone") {
      font-size: 6vw;
    }
  }
}
