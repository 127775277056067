//Photos page related CSS

.photos {
  position: relative;
  width: 100%;
  padding-bottom: 10px;
  min-height: 100vh;
  background-color: #212121;
  @include media("<=phone") {
    font-size: 1.5rem;
    padding-top: 30%;
  }
}
.photoBanner {
  width: 100%;
  top: 0;
  position: relative;
  padding-top: 5%;
  background-image: linear-gradient(
    to top,
    (rgba(0, 0, 0, 0) 2%),
    rgba(33, 33, 33, 0.5) 50%,
    rgba(33, 33, 33, 0.5) 100%
  );
  h1 {
    position: sticky;
    display: block;
    padding-bottom: 5%;
    color: white;
    text-align: center;
    @include media("<=phone") {
      font-size: 1.5rem;
      margin: 0 10px;
    }
    span {
      a {
        text-decoration: none;
      }
    }
  }
}
.imageContainer {
  display: flex;

  width: 100%;
  flex-wrap: wrap;
  margin: 10px auto;
  justify-content: center;
  align-items: center;
  a {

    width: auto;
    margin: 10px 10px;
    @include media("<=tablet") {
      max-width: 90%;
      height: unset;

      max-height: unset;
    }
    @include media("<=phone") {
      height: unset;
    }
  }
  .flickrImage {
    max-width: 750px;
    height: 100%;
    @include media("<=tablet") {
      width: 100%;
      height: unset;
    }
  }
}
.loader {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
}
.load {
  width: 200px;
  @include media("<=phone") {
    width: 50px;
  }
}
